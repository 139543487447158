$(document).ready(function() {
    initHeader();
});

// Global Variables

var isScrolled;
var scrollPosition;
var hasHeader;

var horizontalNavbar = document.querySelector("#horizontalNavbar");

var headerContainer = document.querySelector("#header");
var defaultHeader = document.querySelector("#defaultHeader");
var compactHeader = document.querySelector("#compactHeader");

var navItemTitleLists = document.querySelectorAll('.nav-item-title-list');
var navItemList = document.querySelector('.nav-item-list');

function initHeader() 
{
    // Quick logo change before actual build changes can be done
    // if ( window.location.href.indexOf('11cadogangardens') > -1 ) {
    //   $('.header__logo a').html('<img src="https://11cadogangardens.com/media/10331/cg-logo-landscape.png">');
    // }

    if ( $('.slider').length === 0 ) {
        $('body').addClass('no-slider');
    }

    setHeaderTransparency();
    $(window).scroll(setHeaderTransparency);

    setTimeout(function () {
      $('.header').addClass('visible') // only show once it definitely is the right style
    }, 300);
}

function getScrollPositionAndIsScrolled() 
{
    scrollPosition = window.scrollY;
    var sliderElement = document.querySelector('.slider-exists');
    var horizontalNavTransform = document.querySelector(".gift-horizontal-nav-transform");

    if (sliderElement) 
    {
        hasHeader = true;
    } 
    else
    {
        hasHeader = false;
    }


    if (scrollPosition >= 200 && sliderElement) 
    {
        isScrolled = true;
        // TransformY Compensation for Top Nav
        if(horizontalNavTransform) 
        {
            horizontalNavTransform.classList.add("scrolled");
        }
    } 
    else if (!sliderElement)
    {
        isScrolled = true;
        var giftNav = document.querySelector(".gift-horizontal-nav-transform");

        // TransformY Compensation for Top Nav

        if (horizontalNavTransform) 
        {
            horizontalNavTransform.classList.add("scrolled");
        }
        
        if (giftNav !== null && giftNav !== undefined)
        {
            giftNav.classList.add("scrolled");
        }
    }
    else 
    {
        isScrolled = false;
        // TransformY Compensation for Top Nav
        if (horizontalNavTransform) 
        {
            horizontalNavTransform.classList.remove("scrolled");
        }
        // TransformY Compensation for Top Nav
        var giftNav = document.querySelector(".gift-horizontal-nav-transform");
        
        if (giftNav !== null && giftNav !== undefined)
        {
            giftNav.classList.remove("scrolled");
        }
    }

    handleHorizontalNavbarPosition();
}

function handleHorizontalNavbarPosition() 
{
    // Find a way to make sure this doesn't re-render on scroll ofc.

    if (hasHeader === true && isScrolled === false) 
    {
        // If has header element
        // Handle Default Nav
        headerContainer.classList.remove('compacted');
        horizontalNavbar.style.display = "block";
        horizontalNavbar.style.marginTop = "10px";
        defaultHeader.appendChild(horizontalNavbar);
    }
    else if (hasHeader === false && isScrolled === true || hasHeader === false && isScrolled === true || hasHeader === true && isScrolled === true)
    {
        // Handle Compact Nav
        if (headerContainer) {
            headerContainer.classList.add('compacted');
            horizontalNavbar.style.display = "block";
            horizontalNavbar.style.marginTop = "0px";
            compactHeader.appendChild(horizontalNavbar);
        }
    }
    else 
    {
        // Handle Mobile Nav
    }
}

// Add event listener to each li element in the title list

$('.nav-item-title-list').on('mouseenter', 'a', function(event)
{
    var target = $(event.currentTarget);

    // Check if the target is an anchor tag inside an li

    if (target.is('a') && target.parent().is('li')) 
    {

        var dataGuid = target.attr('data-guid');

        // IF HOVERED ELEMENT HAS A GUID
        if (dataGuid !== null && dataGuid !== undefined) 
        {
            showCorrespondingNavItemContent(dataGuid);
        }

        // IF HOVERED ELEMENT HAS NO GUID
        else 
        {
            var allNavItems = $('.nav-item-list li.top-level-item');
            allNavItems.css('display', 'none');
        }

        // Stop the event from further propagation

        event.stopPropagation();
    }
});

function showCorrespondingNavItemContent(dataGuid) 
{
    var navItemList = document.querySelector('.nav-item-list');

    if (navItemList) 
    {

        var allNavItems = navItemList.querySelectorAll('li.top-level-item');

        var targetItem;

        for (var i = 0; i < allNavItems.length; i++) {
            if (allNavItems[i].getAttribute('data-guid') === dataGuid) {
                targetItem = allNavItems[i];
                break;
            }
        }

        allNavItems.forEach(function (item) {
            item.style.cssText = 'display: none !important;';
        });

        if (targetItem !== null && targetItem !== undefined) 
        {
            targetItem.style.cssText = 'display: block !important;';
        }
    } 
    else 
    {
        console.error('navItemList is not a valid DOM element or does not have a querySelectorAll method.');
    }
}

function setHeaderTransparency() {
    
    // Get Header
    $header = $('.header');

    // If Slider is empty, show "Compacted" Navbar

    if ( $('.slider').length === 0 ) {
        $header.addClass('compacted');
        return;
    }
    
}

// Remove all dropdown menus when not hovering over a Nav Item or Menu Body

$('.top-level-item').on('mouseleave', function () {
    var allNavItems = navItemList.querySelectorAll('li.top-level-item');
    
    allNavItems.forEach(function (item) {
        item.style.cssText = 'display: none !important;';
    });
});

// Add a scroll event listener
window.addEventListener('scroll', getScrollPositionAndIsScrolled);

// Call the function initially to set the correct state based on the initial scroll position
handleHorizontalNavbarPosition();
getScrollPositionAndIsScrolled();